<script>
import UserPermissionsRequestForm from './UserPermissionsRequestForm.vue';

export async function userHasAnyPermissions(uid) {
  const permissionsFound = await db.collection('permissions').where('uid', '==', uid).limit(1).get();

  return permissionsFound.docs.length > 0;
}

export async function userHasPendingRequest(uid) {
  const requestsFound = await db
    .collection('permissionsRequests')
    .where('uid', '==', uid)
    .where('processed', '==', false)
    .limit(1)
    .get();

  return requestsFound.docs.length > 0;
}

export default {
  props: ['facilityInitial', 'adminIsModifying', 'defaults'],
  emits: ['resolved'],

  components: {
    UserPermissionsRequestForm,
  },

  data() {
    return {
      modalShowing: false,
      facilityInitial: '',
      facilities: [],
      groups: [],
      permissionSetsAvailable: {
        auditor: false,
        facilityAdmin: [],
        groupAdmin: [],
        companyAdmin: false,
        superAdmin: false,
      },
      promiseResolversPending: [],
      firebaseToken: '',
      /** @type {string|null} */
      email: null,
      userHasPendingRequest: false,
      adminIsModifying: false,
      submitError: '',
      defaults: this.defaults || {},
      firebaseUnsubscribe: () => {},
    };
  },

  methods: {
    displayModal(facilityInitial, adminIsModifying, defaults) {
      this.modalShowing = true;

      if (facilityInitial) {
        this.facilityInitial = facilityInitial;
      } else {
        this.facilityInitial = '';
      }

      if (adminIsModifying === undefined) {
        adminIsModifying = false;
      }
      this.adminIsModifying = adminIsModifying;
      this.defaults = defaults || {};
      if (this.defaults.email) {
        this.email = this.defaults.email;
      }

      let resolver = () => {};
      const promise = new Promise((resolve) => (resolver = resolve));
      this.promiseResolversPending.push(resolver);
      return promise;
    },

    emitResolved(value) {
      this.$emit('resolved', value);
    },

    async userPermissionsRequested(request) {
      this.emitResolved(request);

      if (request) {
        if (this.adminIsModifying) {
          this.modalShowing = false;
        } else {
          const response = await fetch('/v2/auth/permissions-request', {
            method: 'POST',
            body: JSON.stringify({
              token: this.firebaseToken,
              request,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const responseJson = await response.json();

          if (responseJson.status === 'error') {
            console.error(responseJson);
            this.submitError = response.error;
          }
        }
      } else {
        this.modalShowing = false;
      }
    },

    modalCloseRequested() {
      this.modalShowing = false;
      this.emitResolved(null);
    },
  },

  mounted() {
    this.firebaseUnsubscribe = auth.onIdTokenChanged(async (user) => {
      if (!this.adminIsModifying) {
        this.email = user.email;
      }

      const existingToken = await user.getIdToken();
      if (existingToken) {
        this.firebaseToken = existingToken;

        if (this.facilities.length === 0) {
          const response = await fetch('/v2/auth/list-facilities-and-permission-sets', {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const responseJson = await response.json();
          if (responseJson.status !== 'success') {
            console.error(responseJson);
            return;
          }

          const { facilities, groups, permissionSetsAvailable } = responseJson;
          this.facilities = facilities.filter((f) => f.active);
          this.groups = groups;
          this.permissionSetsAvailable = permissionSetsAvailable;

          this.defaults = this.defaults || {};
          if (this.defaults.email) {
            this.email = this.defaults.email;
          }

          this.modalShowing = true;
        }
      }

      userHasPendingRequest(user.uid).then((userHasPendingRequest) => {
        this.userHasPendingRequest = userHasPendingRequest;
      });
    });
  },

  unmounted() {
    this.firebaseUnsubscribe();
  },
};
</script>

<template>
  <teleport to="body" v-if="modalShowing">
    <div class="user-permissions-request-modal">
      <template v-if="!adminIsModifying">
        <div class="separator">
          <h3>Request Access</h3>

          <p v-if="!userHasPendingRequest">
            You currently don't have access to anything in the Audit Portal. Fill out the form below to request access.
          </p>

          <p v-if="userHasPendingRequest">You currently don't have access to anything in the Audit Portal.</p>

          <p v-if="userHasPendingRequest" class="alert alert-warning">
            Your previous request for access was received and is awaiting review by our admins. Please check back later.
          </p>
        </div>
      </template>

      <template v-if="adminIsModifying">
        <div class="separator">
          <h3>Modify Permission Request</h3>

          <p>Below is what the user entered, modify it to your liking:</p>
        </div>
      </template>

      <div class="minclose" style="cursor: pointer" v-on:click="modalCloseRequested()">&times;</div>
      <div class="form-group" v-if="email">
        <label for="user-add-email" class="control-label">Email:</label>
        <input type="text" v-model="email" id="user-add-email" class="form-control" disabled />
      </div>
      <UserPermissionsRequestForm
        v-if="!userHasPendingRequest"
        v-bind:facilities="facilities"
        v-bind:facilityInitial="facilityInitial"
        v-bind:groups="groups"
        v-bind:permissionSetsAvailable="permissionSetsAvailable"
        v-bind:firebaseToken="firebaseToken"
        v-bind:defaults="defaults"
        v-bind:adminIsModifying="adminIsModifying"
        v-on:userPermissionsRequested="userPermissionsRequested($event)"
      ></UserPermissionsRequestForm>

      <div class="alert alert-danger" v-if="submitError">{{ submitError }}</div>

      <button
        type="submit"
        class="btn btn-secondary"
        style="width: 100%"
        v-if="userHasPendingRequest"
        v-on:click="modalCloseRequested()"
      >
        Dismiss
      </button>
    </div>
  </teleport>
</template>

<style>
.user-permissions-request-modal {
  position: absolute;
  right: 8px;
  top: 8px;
  width: calc(100% - 16px);
  padding: 16px;
  background-color: white;
  z-index: 6000;
  border-radius: 4px;
  box-shadow: 0 0 3px #777;
}

@media (min-width: 500px) {
  .user-permissions-request-modal {
    right: calc(50vw - 200px);
    width: 400px;
    max-width: 50vw;
    animation: user-permissions-request-fade-in 0.7s ease-in-out forwards;
  }
}

.select2-container,
.select2-container--open {
  z-index: 6001;
}

@keyframes user-permissions-request-fade-in {
  0% {
    opacity: 0;
    right: -400px;
  }

  100% {
    opacity: 100;
    right: calc(50vw - 200px);
  }
}

.user-permissions-request-modal .glyphicon-refresh {
  animation: user-permissions-request-rotate 1.5s linear infinite;
}

@keyframes user-permissions-request-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
</style>

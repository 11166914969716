<script>
import Alert from './components/Alert.vue';
import UserPermissionsRequestModal from './components/UserPermissionsRequestModal.vue';
import { userHasAnyPermissions } from './components/UserPermissionsRequestModal.vue';

export default {
  components: {
    Alert,
    UserPermissionsRequestModal,
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      /** @type {string[]} */
      possibleEmails: [],
      nameIsSubmitting: false,
      emailIsSubmitting: false,
      loginCode: '',
      loginCodeIsSubmitting: false,
      mode: /** @type {'name-entry'|'email-selection'|'email-entry'|'code-entry'|'google-pending'|'already-logged-in'|'login-success'} */ 'name-entry',
      userHasAnyPermissions: true,
      permissionsRequestModalShowing: false,
      initialAuthCheck: true,
      /** @type {null|{title: string, msg: string, type: string, duration: number, dismissable: boolean}} */
      alert: null,
      forceFreshLogin: false,
    };
  },

  computed: {
    permissionsRequestReasonShow() {
      return this.email && this.possibleEmails.length > 0 && !this.possibleEmails.includes(this.email);
    },
  },

  mounted() {
    let cookieIsMissingOrExpired = false;
    try {
      const parsed = JSON.parse(atob(/__session=([^;]{26,})/.exec(document.cookie)[1].split('.')[1]));

      if (typeof parsed.email === 'string') {
        console.info('Email from JWT:', parsed.email);
        this.email = parsed.email;
      }

      if (typeof parsed.expShort === 'number') {
        const expiresAt = new Date(parsed.expShort * 1000);
        if (new Date() > expiresAt) {
          cookieIsMissingOrExpired = true;
          console.info('JWT exists but expired at', expiresAt);
        }
      } else if (typeof parsed.exp === 'number') {
        const expiresAt = new Date(parsed.exp * 1000);
        if (new Date() > expiresAt) {
          cookieIsMissingOrExpired = true;
          console.info('JWT exists but expired at', expiresAt);
        }
      }
    } catch (e) {
      // Do nothing, must be an old or malformed cookie.
      cookieIsMissingOrExpired = true;
    }

    auth.onAuthStateChanged(async (user) => {
      const wasInitialAuthCheck = this.initialAuthCheck;
      this.initialAuthCheck = false;

      if (user) {
        console.info('Logged in', user.uid, user.email);
        this.email = user.email;

        if (this.mode === 'name-entry') {
          if (window.location.search.includes('forceFreshLogin=true')) {
            this.forceFreshLogin = true;

            if (this.email) {
              this.mode = 'email-entry';
            }
            return;
          }

          if (wasInitialAuthCheck && cookieIsMissingOrExpired) {
            this.signOut(false);

            if (this.email) {
              this.mode = 'email-entry';
            }
            return;
          }

          this.mode = 'already-logged-in';
        }
      } else {
        console.info('Signed out.');
        if (this.mode === 'already-logged-in') {
          this.mode = 'name-entry';
        }

        // Firebase Auth logins are domain-specific, so we will only be logged in on the domain we saw the login screen
        // from. If we still have a valid token, we can automatically authenticate against Firebase Auth:
        const internalJwtRegExpResult = /__session=([^;]{26,})/.exec(document.cookie);
        if (internalJwtRegExpResult?.[1]) {
          console.info('Signing in via internal token.');

          const response = await this.post('/v2/auth/internal-token-convert/facility-audits', {});
          if (response.status === 'success' && response.token) {
            await auth.signInWithCustomToken(response.token);
          } else {
            console.error(response);
          }
        }
      }
    });
  },

  methods: {
    /**
     * @param {string} title
     * @param {string} msg
     * @param {'danger'|'success'|'warning'} type
     * @param {number} duration
     * @param {boolean} dismissable
     * @param {() => any} [callback]
     */
    alertUser(title, msg, type, duration, dismissable, callback) {
      this.alert = null;
      this.$nextTick(() => {
        this.alert = {
          title,
          msg,
          type,
          duration,
          dismissable,
        };

        if (callback) {
          this.$nextTick(() => callback());
        }
      });
    },

    async post(url, body) {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const json = await response.json();
      return json;
    },

    async nameSubmit() {
      this.nameIsSubmitting = true;
      this.possibleEmails = [];

      try {
        const response = await this.post('/v2/auth/name-to-email', {
          firstName: this.firstName,
          lastName: this.lastName,
        });

        if (response.status !== 'success') {
          this.alertUser('Error', response.error, 'warning', 500, true);
          return;
        }

        this.possibleEmails = response.emails;

        if (this.possibleEmails.length === 0) {
          this.mode = 'email-entry';
        } else {
          this.mode = 'email-selection';
        }
      } finally {
        this.nameIsSubmitting = false;
      }
    },

    async emailSubmit() {
      const email = this.email.toLowerCase();
      this.emailIsSubmitting = true;

      try {
        if (/@jhjplc\.com$/.test(email)) {
          this.googleAuth();
          return;
        }

        const response = await this.post('/v2/auth/login-with-custom-code-start', {
          email,
        });
        if (response.status !== 'success') {
          console.error(response);

          if (response.code === 'domain-unauthorized') {
            const domain = email.split('@')[1];

            this.alertUser(
              'Unauthorized',
              `It looks like ${domain} is not recognized as being part of the Nucor family. We may need to update our approved domain list.<br/><br/>If you'd like to request that ${domain} be added to the list, please provide a division or Nucor contact we can reach with any questions, along with any other relevant details about the reason for your access request: <textarea id="domain-auth-details" class="form-control" placeholder="Nucor or division contact"></textarea> <br/> <a style='cursor:pointer' class="btn btn-primary" id='request-auth'>Click here to send your request.</a>`,
              'warning',
              500,
              true,
              () => {
                const details = document.getElementById('domain-auth-details');
                const btn = document.getElementById('request-auth');
                if (btn && details) {
                  btn.addEventListener('click', async () => {
                    if (!details.value || !details.value.trim()) {
                      alert('You must provide details in order for your request to be processed.');
                      return;
                    }

                    await this.post('/v2/auth/request-domain-auth', { email, details: details.value });
                    this.alertUser('Request Submitted', 'Your request has been submitted.', 'success', 3000, false);
                  });
                } else {
                  console.error('#request-auth or #domain-auth-details is missing.');
                }
              }
            );
          } else {
            this.alertUser('Unauthorized', response.error, 'warning', 500, true);
          }
          return;
        }

        this.mode = 'code-entry';
      } catch (e) {
        console.error(e);

        this.alertUser(
          'Error',
          `An unexpected error occurred. Please try again later. Error was: ${
            e && typeof e === 'object' && 'message' in e ? e.message : e
          }`,
          'danger',
          500,
          true
        );
      } finally {
        this.emailIsSubmitting = false;
      }
    },

    async loginCodeSubmit() {
      const email = this.email.toLowerCase();
      const code = this.loginCode.trim().toUpperCase();
      this.loginCodeIsSubmitting = true;

      try {
        const response = await this.post('/v2/auth/login-with-custom-code-finish', {
          email,
          code,
        });
        if (response.status !== 'success' || !response.token) {
          console.error(response);
          this.alertUser(
            'Incorrect Code',
            'We could not log you in with the given code. Please verify that the code is correct and has not expired.',
            'warning',
            500,
            true
          );
          return;
        }

        const { token } = response;

        await auth.signInWithCustomToken(token);
        await this.internalTokenSetAndNavigate();
      } catch (e) {
        console.error(e);

        this.alertUser(
          'Error',
          "Something went wrong. Please wait a few moments and try again. If the problem persists, contact Brandon Curtis at bcurtis@hiserjoy.com. <p class='small'> Error Details: " +
            JSON.stringify(e.message) +
            '</p>',
          'danger',
          500,
          true
        );
      } finally {
        this.loginCodeIsSubmitting = false;
      }
    },

    async googleAuth() {
      console.info('Authenticating with google...');
      try {
        this.mode = 'google-pending';
        const result = await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());

        if (result.additionalUserInfo.isNewUser == true) {
          await db.collection('users').doc(result.user.email).set(
            {
              email: result.user.email,
              uid: result.user.uid,
              auditor: result.user.displayName,
            },
            { merge: true }
          );
          console.info('User document successfully written!');
        }

        await this.internalTokenSetAndNavigate();
      } catch (e) {
        console.error(e);
        if (e.code === 'auth/popup-closed-by-user') {
          return;
        }

        this.alertUser(
          'Error',
          "Something went wrong. Please wait a few moments and try again. If the problem persists, contact Brandon Curtis at bcurtis@hiserjoy.com. <p class='small'> Error Details: " +
            JSON.stringify(e.message) +
            '</p>',
          'danger',
          500,
          true
        );
      } finally {
        if (this.mode === 'google-pending') {
          this.mode = 'name-entry';
        }
      }
    },

    async internalTokenSetAndNavigate() {
      const { status, code } = await this.post('/v2/auth/internal-token-set', {
        token: await auth.currentUser.getIdToken(),
      });

      this.mode = 'login-success';

      if (status === 'error' && code === 'missing-permissions') {
        this.permissionsRequestModalShowing = true;
        return;
      }

      if (this.forceFreshLogin) {
        return;
      }

      const url = this.buildUrl();
      window.open(url.toString(), '_top');
    },

    /**
     * @param {boolean} clearCookie
     * @returns {Promise<void>}
     */
    async signOut(clearCookie) {
      if (clearCookie) {
        document.cookie = `__session=; Path=/; Domain=${
          /localhost/.test(window.location.href) ? 'localhost' : '.nucor.report'
        }; Max-Age=0;${/^http:\/\//.test(window.location.href) ? '' : ' Secure;'} SameSite=lax`;
      }

      await auth.signOut();
      console.info('signed out');
    },

    newLogin() {
      this.signOut(true);
      this.email = '';
      this.loginCode = '';
      this.mode = 'name-entry';
    },

    buildUrl() {
      const urlCurrent = new URL(window.location.href);

      if (urlCurrent.searchParams.has('urlWanted')) {
        try {
          return new URL(urlCurrent.searchParams.get('urlWanted'));
        } catch (e) {
          console.warn('urlWanted was invalid.');
        }
      }

      const urlBuilding = new URL(window.location.origin);

      for (const [key, value] of urlCurrent.searchParams.entries()) {
        if (key === 'pgWanted') {
          urlBuilding.searchParams.append('pg', value);
        } else if (key === 'pg') {
          if (value !== 'login') {
            urlBuilding.searchParams.append('pg', value);
          }
        } else if (key !== 'sessionid' && key !== 'forceFreshLogin') {
          urlBuilding.searchParams.append(key, value);
        }
      }

      const host = window.location.host;
      if (!urlBuilding.searchParams.has('pg') || urlBuilding.searchParams.get('pg') === 'login') {
        // TODO: 0. Need to change this once we migrate:
        if (/ecms/i.test(host)) {
          urlBuilding.searchParams.set('pg', 'navigate');
        } else if (/(audits|safety|env)/i.test(host)) {
          urlBuilding.searchParams.set('pg', 'navigate');
        } else if (/vendor/i.test(host)) {
          urlBuilding.searchParams.set('pg', 'landing');
        } else if (/apptool/i.test(host)) {
          // Not sure...
        } else if (/(wb|workbook)/i.test(host)) {
          // Not sure...
        } else if (/cr/i.test(host)) {
          urlBuilding.searchParams.set('pg', 'requests');
        }
      }

      return urlBuilding.toString();
    },
  },
};
</script>

<template>
  <div id="user-permissions-request"></div>

  <div class="main-content">
    <div class="container-flex">
      <div class="alert alert-warning force-fresh-login" v-if="forceFreshLogin">
        Your session is expiring, please login again:
      </div>

      <div class="login">
        <img
          width="200"
          style="margin: 10px"
          src="https://firebasestorage.googleapis.com/v0/b/audits-975fa.appspot.com/o/nucor%20logo.PNG?alt=media&token=9cda6f6d-3d6b-45ca-940f-e45eaddcce71"
        />
        <div class="separator"></div>

        <div v-if="mode === 'name-entry'">
          <form v-on:submit.prevent="nameSubmit()">
            <div class="form-group">
              <label for="first-name">First Name:</label>
              <div class="input-group" style="width: 300px">
                <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>
                <input
                  id="first-name"
                  type="text"
                  class="form-control"
                  placeholder="Enter First Name"
                  v-model="firstName"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="last-name">Last Name:</label>
              <div class="input-group" style="width: 300px">
                <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>
                <input
                  id="last-name"
                  type="text"
                  class="form-control"
                  placeholder="Enter Last Name"
                  v-model="lastName"
                />
              </div>
            </div>

            <p></p>
            <button
              class="btn btn-basic btn-block"
              type="submit"
              v-bind:disabled="!firstName || !lastName || nameIsSubmitting"
            >
              {{ nameIsSubmitting ? 'One moment...' : 'Continue' }}
            </button>
          </form>

          <div
            style="position: absolute; right: 10px; color: #999; bottom: -25px; font-size: 12px; cursor: pointer"
            v-on:click="googleAuth()"
          >
            Admin Sign In
          </div>
        </div>

        <div v-if="mode === 'email-selection'">
          <template v-if="emailIsSubmitting">
            <p>Please wait a moment...</p>
          </template>
          <template v-if="!emailIsSubmitting">
            <p>Select your account:</p>

            <a
              v-for="email of possibleEmails"
              class="email-to-click"
              href="javascript:void(0)"
              @click.prevent="
                this.email = email;
                emailSubmit();
              "
            >
              {{ email }}
            </a>

            <p style="margin-top: 16px">
              Or
              <a href="javascript:void(0)" @click.prevent="mode = 'email-entry'">log in with a new account.</a>
            </p>
          </template>
        </div>

        <div v-if="mode === 'email-entry'">
          <form v-on:submit.prevent="emailSubmit()">
            <div class="form-group">
              <label for="email">Company Email Address:</label>
              <div class="input-group" style="width: 300px">
                <span class="input-group-addon"><i class="glyphicon glyphicon-envelope"></i></span>
                <input
                  id="email"
                  type="text"
                  class="form-control"
                  placeholder="Enter Nucor Email Address"
                  v-model="email"
                />
              </div>
            </div>
            <p></p>
            <button
              class="btn btn-basic btn-block"
              type="submit"
              v-bind:disabled="!email.includes('@') || email.length < 3 || emailIsSubmitting"
            >
              {{ emailIsSubmitting ? 'One moment...' : 'Continue' }}
            </button>
          </form>

          <div
            style="position: absolute; right: 10px; color: #999; bottom: -25px; font-size: 12px; cursor: pointer"
            v-on:click="googleAuth()"
          >
            Admin Sign In
          </div>
        </div>

        <div style="width: 300px" v-if="mode === 'code-entry'">
          <div>
            <p>
              Check your <span>{{ email }}</span> inbox. We've sent you an email with a code:
            </p>
            <form v-on:submit.prevent="loginCodeSubmit()">
              <div class="input-group" style="width: 300px">
                <span class="input-group-addon"><i class="glyphicon glyphicon-log-in"></i></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Login Code"
                  minlength="4"
                  maxlength="50"
                  pattern="^[a-zA-Z0-9]+$"
                  title="Login codes include only letters and numbers."
                  autocomplete="off"
                  v-model="loginCode"
                />
              </div>
              <p></p>
              <button class="btn btn-basic btn-block" type="submit" v-bind:disabled="loginCodeIsSubmitting">
                {{ loginCodeIsSubmitting ? 'One moment...' : 'Login' }}
              </button>
            </form>
          </div>
        </div>

        <div v-if="mode === 'google-pending'">
          <div>Awaiting response from Google...</div>
        </div>

        <div v-if="mode === 'login-success'">
          <div class="circle" style="background: #559de0">
            <span class="glyphicon glyphicon-ok" style="color: #fff; font-size: 45pt; padding: 20px"></span>
          </div>
          <div class="separator"></div>
          <p>
            Sign In Successful! Please wait while<br /><a v-bind:href="buildUrl()"
              >we redirect you to the audit portal</a
            >...
          </p>
        </div>

        <div v-if="mode === 'already-logged-in'">
          <a style="color: #fff" class="btn btn-primary btn-block" v-if="userHasAnyPermissions" v-bind:href="buildUrl()"
            >Continue as {{ email }}</a
          >
          <p></p>
          <button class="btn btn-basic btn-block" type="button" v-on:click="newLogin()">Switch Accounts</button>
        </div>
      </div>
    </div>
  </div>
  <Alert
    v-if="alert"
    v-bind:title="alert.title"
    v-bind:msg="alert.msg"
    v-bind:type="alert.type"
    v-bind:duration="alert.duration"
    v-bind:dismissable="alert.dismissable"
  />
  <UserPermissionsRequestModal
    v-if="permissionsRequestModalShowing"
    :defaults="{
      firstName,
      lastName,
      reasonShow: permissionsRequestReasonShow,
      possibleEmails: possibleEmails,
      emailUsing: email,
    }"
  />
</template>

<style>
.force-fresh-login {
  margin: 0 auto;
  width: 360px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(20% - 60px);
}

label {
  text-align: left;
  display: block;
}

.email-to-click {
  padding: 16px;
  border-bottom: 1px solid #eee;
  display: block;
}

.email-to-click:hover {
  background-color: #eee;
}
</style>
